import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Stack,
} from '@chakra-ui/react';
import {
  Cargo,
  CargoType,
  DimensionUnit,
  TimeOfDay,
  WeightUnit,
} from '@pelicargo/types';
import { getPrettyTimeOfDay, prettyDate } from '@pelicargo/utils';
import { useMemo, useState } from 'react';
import { HiChevronLeft } from 'react-icons/hi';
import { HiShare } from 'react-icons/hi2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BookingForm } from '../../components/BookingForm';
import { FlightSegmentsCard } from '../../components/FlightSegmentsCard';
import { Page } from '../../components/Page';
import { PriceCardTabs } from '../../components/PriceCardTabs';
import { RequestDetailsCard } from '../../components/RequestDetailsCard';
import { trpc } from '../../config/trpc';
import { CONTAINER_2XL } from '../../utils/constants';

type Params = {
  quoteId?: string;
};

export const BookQuote = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params: Params = useParams();

  const { data } = trpc.getQuote.useQuery({ id: Number(params?.quoteId) });

  const quote = data?.quote;
  const request = quote?.request;

  const [tabIndex, setTabIndex] = useState(
    location.state.quoteVariantIndex || 0,
  );

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const trimmedFlightSegments = useMemo(() => {
    const segments = [];

    quote?.flight_path?.forEach((node) => {
      segments.push({
        origin: node?.origin_airport_id,
        destination: node?.destination_airport_id,
        aircraftType: node?.aircraft_type,
      });
    });

    return segments;
  }, [quote?.flight_path]);

  const tenderDate = useMemo(() => {
    if (quote?.tender_date_time_of_day) {
      const date = prettyDate(quote?.tender_date);
      const time = getPrettyTimeOfDay(
        quote?.tender_date_time_of_day as TimeOfDay,
      );
      return `${date} ${time}`;
    }

    return prettyDate(quote?.tender_date);
  }, [quote?.tender_date, quote?.tender_date_time_of_day]);

  const quoteVariant = useMemo(() => {
    return quote?.quote_variants?.[tabIndex];
  }, [quote?.quote_variants, tabIndex]);

  return (
    <Page title="Book Quote">
      <Container
        w="full"
        maxW={CONTAINER_2XL}
        pt={{ base: '8', lg: '10' }}
        pb={{ base: '8', lg: '20' }}
        px={{ base: '4', lg: '12' }}
      >
        <Stack spacing="6">
          <Stack justify="space-between" direction="row">
            <Button
              variant="outline"
              size="sm"
              leftIcon={<HiChevronLeft />}
              onClick={() => navigate(`/requests/${quote?.request?.id}`)}
            >
              Quotes
            </Button>
            <Button variant="ghost" size="sm" leftIcon={<HiShare />}>
              Share Listing
            </Button>
          </Stack>
          <Stack direction={{ base: 'column', xl: 'row' }} w="full" spacing="6">
            <Stack w="full" spacing="4">
              <FlightSegmentsCard
                airlineLogoUri={quote?.airline?.logo_uri}
                airlineName={quote?.airline?.name}
                flightSegments={trimmedFlightSegments}
                estimatedTransitTime={quote?.estimated_transit_time}
                originAirportId={quote?.origin_airport?.id}
                destinationAirportId={quote?.destination_airport?.id}
              />
            </Stack>
            <Stack w="full">
              <Card h="auto">
                <CardBody p="0">
                  <PriceCardTabs
                    tabIndex={tabIndex}
                    onTabChange={handleTabsChange}
                    quoteVariants={quote?.quote_variants}
                    cw={quote?.request?.chargeable_weight}
                    expiresAt={quote?.expires_at}
                    border="none"
                  />
                </CardBody>
                <CardFooter
                  borderTopWidth="1px"
                  borderColor="border.default"
                  px="4"
                  pb="3"
                >
                  <BookingForm
                    requestId={request?.id}
                    quoteId={quote?.id}
                    customer_ref={request?.customer_ref}
                    awb_number={request?.awb_number}
                    documents={request?.documents}
                    cargo={request?.cargo}
                    quoteVariant={quoteVariant}
                  />
                </CardFooter>
              </Card>
              <RequestDetailsCard
                cargoType={quote?.request?.cargo_type as CargoType}
                cargo={quote?.request?.cargo as Cargo[]}
                isKnownShipper={quote?.request?.is_known_shipper}
                commodity={quote?.request?.commodity}
                volumetricWeight={quote?.request?.volumetric_weight}
                grossWeight={quote?.request?.gross_weight}
                tenderDate={tenderDate}
                dimensionUnit={
                  quote?.request?.original_dimension_unit as DimensionUnit
                }
                weightUnit={quote?.request?.original_weight_unit as WeightUnit}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
};
